import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Utah",
  "subtitle": "May 2018",
  "category": "USA"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2 {...{
      "id": "general"
    }}>{`General`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://store.usgs.gov/"
        }}>{`National Park Pass`}</a>{` - 1 pass per car`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/Utah_State_Route_12"
        }}>{`Scenic Byway 12`}</a>{`- Can be driven on from Bryce to Capitol Reef`}</li>
    </ul>
    <h2 {...{
      "id": "zion-national-park"
    }}>{`Zion National Park`}</h2>
    <p>{`Included in the National Park Pass`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.nps.gov/zion/planyourvisit/angels-landing-hiking-permits.htm"
        }}>{`Angel’s Landing`}</a>{` - Permit required`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.nps.gov/zion/planyourvisit/thenarrows.htm"
        }}>{`Narrows hike`}</a>{` - It was very nice!`}</li>
    </ul>
    <h2 {...{
      "id": "bryce-canyon-national-park"
    }}>{`Bryce Canyon National Park`}</h2>
    <p>{`Included in the National Park Pass, $30/car`}</p>
    <ul>
      <li parentName="ul">{`Park by the visitor center and took the shuttle in`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.nps.gov/brca/planyourvisit/rimtrail.htm"
        }}>{`Rim Trail`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.nps.gov/brca/planyourvisit/qgnavajocombo.htm"
        }}>{`Queens Garden & Navajo Combination Trail`}</a></li>
    </ul>
    <h2 {...{
      "id": "capitol-reef-national-park"
    }}>{`Capitol Reef National Park`}</h2>
    <p>{`Included in the National Park Pass, $30/car`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.alltrails.com/trail/us/utah/goosenecks-sunset-point"
        }}>{`Goosenecks Overlook`}</a>{` - It was great for sunset.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.alltrails.com/trail/us/utah/hickman-bridge-trail"
        }}>{`Hickman Bridge`}</a>{` - 2 miles, round trip`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.nps.gov/care/learn/nature/night-sky.htm"
        }}>{`Night sky tours`}</a>{` - Guided full moon walk at 10 PM was cool`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.alltrails.com/trail/us/utah/panorama-point--3"
        }}>{`Panorama point`}</a>{` - It was so-so. I think some other spots are nicer.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.nps.gov/care/planyourvisit/scenicdrive.htm"
        }}>{`Scenic Drive`}</a>{` - 20 miles, round trip`}</li>
    </ul>
    <h2 {...{
      "id": "goblin-valley-state-park"
    }}>{`Goblin Valley State Park`}</h2>
    <p>{`State Park! `}<strong parentName="p">{`NOT`}</strong>{` included in National Park Pass. Around 1.5 hour drive from Torrey. This park is cool, but it's definitely a detour.`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/yXu4yZCUiFWDViVYA"
        }}>{`Valley of Goblins`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/u9CojPwHSysMJ63a8"
        }}>{`The Three Sisters`}</a>{` - It’s relatively easy to see from the road`}</li>
    </ul>
    <h2 {...{
      "id": "moab"
    }}>{`Moab`}</h2>
    <ul>
      <li parentName="ul">{`This is a good hub to stay for visiting Arches and Canyonlands`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/KWLXvzRCQTFS99vf6"
        }}>{`City Market`}</a>{` - Discounted donuts at the end of the day`}</li>
    </ul>
    <h2 {...{
      "id": "arches-national-park"
    }}>{`Arches National Park`}</h2>
    <p>{`Included in the National Park Pass, $25/car. About 10 minutes drive from Moab.`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.nps.gov/arch/planyourvisit/delicate-arch.htm"
        }}>{`Delicate Arch`}</a>{` - Nice sunset but very crowded. Wear good shoes since it’s sloped.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.nps.gov/arch/planyourvisit/courthouse-towers.htm"
        }}>{`Park Avenue and Courthouse Towers`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.nps.gov/arch/planyourvisit/the-windows.htm"
        }}>{`The Windows Section`}</a>{` - Turret Arch`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.nps.gov/thingstodo/arches-hike-landscape-arch.htm"
        }}>{`Landscape Arch`}</a>{` - Very long and narrow`}</li>
    </ul>
    <h2 {...{
      "id": "canyonlands"
    }}>{`Canyonlands`}</h2>
    <p>{`Included in the National Park Pass, $25/car. About 35 minutes drive from Moab.`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.nps.gov/articles/cany-upheaval-dome.htm"
        }}>{`Upheaval Dome`}</a>
        <ul parentName="li">
          <li parentName="ul">{`1st outlook: 0.8 miles, around 1 hour - Easy`}</li>
          <li parentName="ul">{`2nd outlook: 0.8 miles, around 1.5 hours - Easy but has a bit of rock scrambling`}</li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.nps.gov/thingstodo/cany-mesa-arch.htm"
        }}>{`Mesa Arch`}</a>{` - 0.5 miles, around 30 minutes; Better for sunrise`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://goo.gl/maps/APtEKLibigDdH7f76"
        }}>{`Green River Outlook`}</a>{` - The spot for sunset`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.nps.gov/thingstodo/cany-grand-view-point.htm"
        }}>{`Grand View Point Outlook`}</a>
        <ul parentName="li">
          <li parentName="ul">{`Mostly stopped for a photo`}</li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://www.nps.gov/glca/planyourvisit/orange-cliffs.htm"
            }}>{`Orange Cliffs Overlook`}</a>{` - Next to it`}</li>
        </ul>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      